import axios from 'axios';
import { proxy } from 'valtio';
import { derive } from 'valtio/utils';
import { sortByKey } from './lib';

export const authState = proxy<{ jwt: null | string }>({ jwt: null });
export const settingsState = proxy<Settings>({ state: 'READY', settings: {} });
export const slotsState = proxy<Slots>({ state: 'READY', slots: {} });
export const accusState = proxy<Accus>({ state: 'READY', accus: {} });
export const productsState = proxy<Products>({ state: 'READY', products: {} });
export const checkoutState = proxy<Checkout>({
  products: [],
  date: { d: 0, m: 0, y: 0 },
  name: '',
  email: '',
  phone: '',
  address: '',
  postcode: '',
  city: '',
  terms: false,
  shipment: 'INSTORE',
  spoed: false,
  workload: 0
});

export const ordersState = proxy<Orders>({ state: 'READY', orders: [] });

export const catalogState = derive<any, Catalog>({
  catalog: (get) => {
    if (
      get(accusState).state !== 'LOADED' ||
      get(productsState).state !== 'LOADED'
    )
      return [];
    return Object.values(get(accusState).accus)
      .sort(sortByKey('name'))
      .map((accu) => ({
        ...accu,
        products: get(productsState).products[accu.id] || []
      }));
  }
});

export function logout() {
  authState.jwt = null;
}

export function setSettingsState(state: Settings['state']) {
  settingsState.state = state;
}

export function setSlotsState(state: Slots['state']) {
  slotsState.state = state;
}

export function setAccusState(state: Accus['state']) {
  accusState.state = state;
}

export function setOrdersState(state: Orders['state']) {
  ordersState.state = state;
}

export function setProductsState(state: Products['state']) {
  productsState.state = state;
}

export function setSettings(settings: Setting[]) {
  settingsState.settings = settings.reduce<Record<string, Setting>>(
    (settings, setting) => {
      settings[setting.name] = setting;
      return settings;
    },
    {}
  );
  settingsState.state = 'LOADED';
}

export function setOrders(orders: any[]) {
  ordersState.orders = orders;
  ordersState.state = 'LOADED';
}

export function setSlots(slots: Slot[]) {
  slotsState.slots = slots.reduce<Record<string, number>>((slots, slot) => {
    slots[`${slot.d}-${slot.m}-${slot.y}`] = slot.free;
    return slots;
  }, {});
  slotsState.state = 'LOADED';
}

export function setAccus(accus: Accu[]) {
  accusState.accus = accus.reduce<Record<number, Accu>>((accus, accu) => {
    accus[accu.id] = accu;
    return accus;
  }, {});
  accusState.state = 'LOADED';
}

export function setProducts(products: Product[]) {
  productsState.products = products.reduce<Record<number, Product[]>>(
    (products, product) => {
      if (!products[product.accu]) products[product.accu] = [];
      products[product.accu].push(product);
      return products;
    },
    {}
  );
  productsState.state = 'LOADED';
}

const prevSettings: Record<string, Setting> = {};
export async function setSetting(setting: Setting, value: number) {
  if (!prevSettings[setting.name])
    prevSettings[setting.name] = settingsState.settings[setting.name];

  settingsState.settings[setting.name] = {
    ...setting,
    value
  };
}

export function saveSettings() {
  const settings = Object.keys(prevSettings);
  if (settings.length === 0) return;
  settings.forEach(async (setting) => {
    try {
      const response = await axios.put(
        '/api/settings',
        settingsState.settings[setting],
        {
          headers: { Authorization: `Bearer ${authState.jwt}` }
        }
      );
      settingsState.settings[response.data.name] = response.data;
    } catch {
      settingsState.settings[setting] = prevSettings[setting];
    }
    delete prevSettings[setting];
  });
}

const prevSlots: Record<string, number> = {};
export async function setSlot(slot: string, value: number) {
  if (!prevSlots[slot]) prevSlots[slot] = slotsState.slots[slot];

  slotsState.slots[slot] = Math.max(value, 0);
}

export function saveSlots() {
  const slots = Object.keys(prevSlots);
  if (slots.length === 0) return;
  slots.forEach(async (slot) => {
    try {
      const [d, m, y] = slot.split('-').map(Number);
      await axios.put(
        `/api/slots`,
        {
          d,
          m,
          y,
          free: slotsState.slots[slot]
        },
        {
          headers: { Authorization: `Bearer ${authState.jwt}` }
        }
      );
    } catch {
      slotsState.slots[slot] = prevSlots[slot];
    }
  });
}

export async function saveAccu(accu: Accu | CatalogEntry) {
  //@ts-ignore
  const { products, ..._accu } = accu;
  const prevAccu = accusState.accus[_accu.id];
  accusState.accus[_accu.id] = _accu;
  try {
    await axios.put('/api/accus', _accu, {
      headers: { Authorization: `Bearer ${authState.jwt}` }
    });
  } catch {
    accusState.accus[_accu.id] = prevAccu;
  }
}

export async function deleteAccu(accu: Accu | CatalogEntry) {
  try {
    await axios.delete(`/api/accus?id=${accu.id}`, {
      headers: { Authorization: `Bearer ${authState.jwt}` }
    });
    delete accusState.accus[accu.id];
    delete productsState.products[accu.id];
  } catch {}
}

export async function saveProduct(product: Product) {
  const prevProducts = productsState.products[product.accu];
  productsState.products[product.accu] = productsState.products[
    product.accu
  ].map((prod) => (prod.id === product.id ? product : prod));
  try {
    await axios.put('/api/products', product, {
      headers: { Authorization: `Bearer ${authState.jwt}` }
    });
  } catch {
    productsState.products[product.accu] = prevProducts;
  }
}

export async function deleteProduct(product: Product) {
  try {
    await axios.delete(`/api/products?id=${product.id}`, {
      headers: { Authorization: `Bearer ${authState.jwt}` }
    });
    productsState.products[product.accu] = productsState.products[
      product.accu
    ].filter(({ id }) => id !== product.id);
  } catch {}
}

export async function sendConfirm(id: string) {
  try {
    await axios.post(
      '/api/send/confirm',
      { id },
      {
        headers: { Authorization: `Bearer ${authState.jwt}` }
      }
    );
  } catch (err) {
    console.error(err);
    alert('Bevestiging kon niet verzonden worden.');
  }
}

export async function sendInvoice(id: string) {
  try {
    await axios.post(
      '/api/send/invoice',
      { id },
      {
        headers: { Authorization: `Bearer ${authState.jwt}` }
      }
    );
  } catch (err) {
    console.error(err);
    alert('Factuur kon niet verzonden worden.');
  }
}

export async function sendTrackAndTrace(data: TrackAndTraceData) {
  try {
    await axios.post(
      '/api/send/tt',
      { id: data.uuid, ...data },
      {
        headers: { Authorization: `Bearer ${authState.jwt}` }
      }
    );
  } catch (err) {
    console.error(err);
  }
}

export interface Setting {
  name: string;
  value: number;
}

export interface Settings {
  state: 'LOADED' | 'LOADING' | 'READY';
  settings: Record<string, Setting>;
}

export interface Slot {
  d: number;
  m: number;
  y: number;
  free: number;
}

export interface Slots {
  state: 'LOADED' | 'LOADING' | 'READY';
  slots: Record<string, number>;
}

export interface Accu {
  id: number;
  name: string;
  voltage: number;
  capacity: number;
  description: string;
}

export interface Accus {
  state: 'LOADED' | 'LOADING' | 'READY';
  accus: Record<number, Accu>;
}

export interface Orders {
  state: 'LOADED' | 'LOADING' | 'READY';
  orders: Order[];
}

export interface Order {
  id: number;
  uuid: string;
  transactionId: string;
  data: Checkout;
  status: string;
}

export interface Product {
  id: number;
  accu: number;
  capacity: number;
  slots: number;
  price: number;
}

export interface Products {
  state: 'LOADED' | 'LOADING' | 'READY';
  products: Record<number | string, Product[]>;
}

export interface CatalogEntry extends Accu {
  products: Product[];
}

export interface Catalog {
  catalog: CatalogEntry[];
}

export interface CheckoutProduct {
  name: string;
  price: number;
}

export interface Checkout {
  products: CheckoutProduct[];
  date: {
    d: number;
    m: number;
    y: number;
  };
  name: string;
  email: string;
  phone: string;
  address: string;
  postcode: string;
  city: string;
  terms: boolean;
  shipment: 'POST' | 'INSTORE' | 'INSTORECASH';
  spoed: boolean;
  workload: number;
}

export interface TrackAndTraceData {
  uuid: string;
  order: Order;
  d: string;
  b: string;
  p: string;
  l: string;
}
