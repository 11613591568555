import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '../components/Text';

export const Bedankt: FC = () => (
  <Text>
    <h1>Bedankt</h1>
    <p>Bedankt voor uw bestelling bij Helder Accus Service.</p>
    <p>U ontvangt van ons een e-mail met een bevestiging van uw bestelling.</p>
    <p>
      Mocht u verder vragen hebben, dan kunt u ons bereiken via de{' '}
      <Link to="/contact">Contact pagina</Link>.
    </p>
  </Text>
);
