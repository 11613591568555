import axios from 'axios';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import {
  accusState,
  CatalogEntry,
  catalogState,
  productsState,
  setAccus,
  setAccusState,
  setProducts,
  setProductsState
} from '../store';

export function useCatalog() {
  const { catalog } = useSnapshot(catalogState);
  const accus = useSnapshot(accusState);
  const products = useSnapshot(productsState);

  useEffect(() => {
    if (accus.state === 'READY') {
      setAccusState('LOADING');
      axios
        .get('/api/accus')
        .then((response) => {
          setAccus(response.data);
        })
        .catch(() => {
          setAccusState('READY');
        });
    }
  }, [accus.state]);

  useEffect(() => {
    if (products.state === 'READY') {
      setProductsState('LOADING');
      axios
        .get('/api/products')
        .then((response) => {
          setProducts(response.data);
        })
        .catch(() => {
          setProductsState('READY');
        });
    }
  }, [products.state]);

  const state: State =
    products.state === 'LOADED' && accus.state === 'LOADED'
      ? 'LOADED'
      : 'LOADING';
  return { catalog: Object.values(catalog) as CatalogEntry[], state };
}

type State = 'LOADED' | 'LOADING';
