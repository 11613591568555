import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select
} from 'antd';
import axios from 'axios';
import { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from '../components/Text';
import { useSettings } from '../hooks/useSettings';
import { Checkout, CheckoutProduct, authState, ordersState } from '../store';

export const ManualOrder: FC = () => {
  const { settings } = useSettings();
  const [count, setCount] = useState<number>(1);
  const history = useHistory();

  async function onFinish(data: Data) {
    const newOrder: Checkout = {
      products: [
        ...Array.from({ length: count }).map<CheckoutProduct>((v, i) => ({
          name: data[`product${i}`],
          price: data[`price${i}`]
        })),
        {
          name: `Garantie ${data.warranty ? 2 : 1} jaar`,
          price: data.warranty ? Number(settings.garantie.value) : 0
        }
      ],
      date: {
        d: Number(data.date.format('DD')),
        m: Number(data.date.format('MM')),
        y: Number(data.date.format('YYYY'))
      },
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      postcode: data.postcode,
      spoed: false,
      city: data.city,
      terms: true,
      shipment: 'INSTORECASH',
      workload: 1
    };

    await axios.post('/api/orders', newOrder, {
      headers: { Authorization: `Bearer ${authState.jwt}` }
    });
    ordersState.state = 'READY';
    history.push('/orders');
  }

  return (
    <Text>
      <Form
        name="create"
        layout="horizontal"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 6 }}
        onFinish={onFinish}
        initialValues={{ count: count, price1: 0, warranty: false }}
      >
        <h2>Order aanmaken</h2>
        <Form.Item name="count" label="Aantal Producten">
          <InputNumber onChange={(ev) => setCount(ev as number)} />
        </Form.Item>
        <Form.Item
          name="date"
          label="Revisie datum"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Divider />
        <h3>Producten</h3>
        {Array.from({ length: count }).map((v, i) => (
          <Fragment key={i}>
            <Form.Item
              rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
              name={`product${i}`}
              label={`Product ${i + 1}`}
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
              name={`price${i}`}
              label={`price ${i + 1}`}
            >
              <InputNumber
                formatter={(value) => `€ ${value}`}
                parser={(value) => value!.replace('€ ', '')}
              />
            </Form.Item>
            <Divider />
          </Fragment>
        ))}
        <Form.Item
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          name="warranty"
          label="Garantie"
        >
          <Select>
            <Select.Option value={false}>1 jaar (gratis)</Select.Option>
            <Select.Option value={true}>
              2 jaar (€ {settings.garantie?.value})
            </Select.Option>
          </Select>
        </Form.Item>
        <Divider />
        <h3>Gegevens</h3>
        <Form.Item
          name="name"
          label="Naam"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            { required: true, message: 'Dit veld in verplicht.' },
            { type: 'email', message: 'Dit is geen geldig e-mailadres.' }
          ]}
          hasFeedback
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Bevestig E-mail"
          dependencies={['email']}
          hasFeedback
          rules={[
            { required: true, message: 'Dit veld in verplicht.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error(
                      'De opgegeven e-mail adressen zijn niet hetzelfde.'
                    )
                  );
                }
              }
            })
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Telefoon"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input placeholder="06 12345678" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Adres"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input placeholder="Straat + huisnummer" />
        </Form.Item>
        <Form.Item
          name="postcode"
          label="Postcode"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input placeholder="1234 AB" maxLength={7} style={{ width: 80 }} />
        </Form.Item>
        <Form.Item
          name="city"
          label="Woonplaats"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={{ span: 24 }} sm={{ offset: 6, span: 18 }}>
              <Button type="primary" htmlType="submit">
                Aanmaken
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Text>
  );
};

interface Data {
  warranty: boolean;
  date: any;
  name: string;
  email: string;
  phone: string;
  address: string;
  postcode: string;
  city: string;
  [key: string]: any;
}
