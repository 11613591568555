import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Space, Spin } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import { useSnapshot } from 'valtio';
import { authState, CatalogEntry, deleteAccu, productsState } from '../store';

export const NewProduct: FC<{
  item: CatalogEntry;
}> = ({ item }) => {
  const { jwt } = useSnapshot(authState);
  const [showForm, setShowForm] = useState(false);
  const [state, setState] = useState<State>('READY');

  const onFinish = async (data: Data) => {
    setState('PENDING');
    try {
      const result = await axios.post(
        '/api/products',
        { accu: item.id, ...data },
        {
          headers: { Authorization: `Bearer ${jwt}` }
        }
      );
      if (item.products.length === 0) {
        productsState.products[item.id] = [result.data];
      } else {
        productsState.products[item.id].push(result.data);
      }
      setShowForm(false);
      setState('READY');
    } catch {
      setState('ERROR');
    }
  };

  function handleDelete() {
    if (
      // eslint-disable-next-line
      confirm(
        'Weet u zeker dat u deze accu en alle onderliggende producten wilt verwijderen?'
      )
    )
      deleteAccu(item);
  }

  return (
    <>
      {!showForm && (
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowForm(true)}
          >
            Product toevoegen
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={handleDelete}
          >
            Accu Verwijderen
          </Button>
        </Space>
      )}
      {showForm && (
        <Spin spinning={state === 'PENDING'} size="large">
          <Form
            name="newProduct"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            initialValues={{ slots: 1 }}
          >
            <h3>Product toevoegen</h3>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  name="capacity"
                  label="Capaciteit"
                  rules={[
                    { required: true, message: 'Dit veld in verplicht.' }
                  ]}
                >
                  <InputNumber autoFocus addonAfter="Ah" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="price"
                  label="Prijs"
                  rules={[
                    { required: true, message: 'Dit veld in verplicht.' }
                  ]}
                >
                  <InputNumber prefix="€" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="slots"
                  label="Werklast"
                  rules={[
                    { required: true, message: 'Dit veld in verplicht.' }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Toevoegen
                </Button>
                <Button htmlType="reset" onClick={() => setShowForm(false)}>
                  Annuleren
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      )}
    </>
  );
};

type State = 'READY' | 'PENDING' | 'DONE' | 'ERROR';

interface Data {
  capacity: number;
  slots: number;
  price: number;
}
