import { Spin } from 'antd';
import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AllProducts } from '../components/AllProducts';
import { Product } from '../components/Product';
import { useCatalog } from '../hooks/useCatalog';
import { useSettings } from '../hooks/useSettings';
import classes from './products.module.css';

export const Products: FC = () => {
  useSettings();
  const { state, catalog } = useCatalog();
  const { params } = useRouteMatch<{ id?: string }>();

  const product = useMemo(() => {
    if (!params.id || state === 'LOADING') return null;
    return catalog.find((item) => item.id === (params.id as any)) || null;
  }, [params.id, catalog, state]);

  if (state === 'LOADING')
    return (
      <div className={classes.spin}>
        <Spin />
      </div>
    );

  if (product) return <Product product={product} />;

  return <AllProducts />;
};
