import { Col, Divider, Row } from 'antd';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { CatalogEntry } from '../store';
import { OrderForm } from './OrderForm';
import classes from './product.module.css';
import { Text } from './Text';

export const Product: FC<{ product: CatalogEntry }> = ({ product }) => (
  <Text>
    <Row gutter={[16, 16]}>
      <Col
        xs={24}
        sm={8}
        className={classes.pic}
        style={{ backgroundImage: `url(/pics/${product.id}.jpg)` }}
      />
      <Col xs={24} sm={16}>
        <h1>{product.name}</h1>
        <p>
          <strong>Voltage:</strong> {product.voltage}V
        </p>
        <p>
          <strong>Originele capaciteit:</strong> {product.capacity}Ah
        </p>
        <Divider />
        <ReactMarkdown>{product.description}</ReactMarkdown>
      </Col>
    </Row>
    <Divider />
    <OrderForm product={product} />
  </Text>
);
