import { useSnapshot } from 'valtio';
import { authState, ordersState, setOrders, setOrdersState } from '../store';
import { useEffect } from 'react';
import axios from 'axios';

export function useOrders() {
  const orders = useSnapshot(ordersState);
  const { jwt } = useSnapshot(authState);

  useEffect(() => {
    if (orders.state === 'READY') {
      setOrdersState('LOADING');
      axios
        .get('/api/orders', {
          headers: { Authorization: `Bearer ${jwt}` }
        })
        .then((response) => {
          setOrders(
            response.data
              .map((order: any) => ({
                ...order,
                data: JSON.parse(order.data)
              }))
              .reverse()
          );
        });
    }
  }, [orders.state, jwt]);

  return orders;
}
