import axios from 'axios';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { setSlots, setSlotsState, slotsState } from '../store';

export function useSlots() {
  const slots = useSnapshot(slotsState);

  useEffect(() => {
    if (slots.state === 'READY') {
      setSlotsState('LOADING');
      axios
        .get('/api/slots')
        .then((response) => {
          setSlots(response.data);
        })
        .catch(() => {
          setSlotsState('READY');
        });
    }
  }, [slots.state]);

  return slots;
}
