import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Spin } from 'antd';
import { FC, useCallback, useMemo, useState } from 'react';
import { Text } from '../components/Text';
import { useSettings } from '../hooks/useSettings';
import { useSlots } from '../hooks/useSlots';
import { saveSlots, setSlot } from '../store';
import classes from './slots.module.css';

const MONTHS = [
  '',
  'Januari',
  'Februari',
  'Maart',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Augustus',
  'September',
  'Oktober',
  'November',
  'December'
];

const DAYS = [
  'Zondag',
  'Maandag',
  'Dinsdag',
  'Woensdag',
  'Donderdag',
  'Vrijdag',
  'Zaterdag'
];

const D = new Date().getDate();
const M = new Date().getMonth() + 1;
const Y = new Date().getFullYear();

export const Slots: FC = () => {
  const { settings } = useSettings();
  const { state, slots } = useSlots();
  const [page, setPage] = useState<Page>({ m: M, y: Y });
  const daysInMonth = useMemo(
    () => new Date(page.y, page.m, 0).getDate(),
    [page]
  );

  const prevDisabled = useMemo(() => page.m === M && page.y === Y, [page]);

  const next = useCallback(() => {
    setPage((page) => {
      if (page.m < 12) {
        return { ...page, m: page.m + 1 };
      } else {
        return { m: 1, y: page.y + 1 };
      }
    });
  }, []);

  const prev = useCallback(() => {
    setPage((page) => {
      if (page.m > 1) {
        return { ...page, m: page.m - 1 };
      } else {
        return { m: 12, y: page.y - 1 };
      }
    });
  }, []);

  const formFields = useMemo(() => {
    let fields: Field[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      fields.push({
        d: i,
        ...page,
        day: DAYS[new Date(page.y, page.m - 1, i).getDay()],
        month: MONTHS[page.m],
        slot: `${i}-${page.m}-${page.y}`
      });
    }
    return fields;
  }, [daysInMonth, page]);

  return (
    <Text>
      <header className={classes.header}>
        <Button
          size="large"
          icon={<ArrowLeftOutlined />}
          onClick={prev}
          disabled={prevDisabled}
        />
        <h1>
          {MONTHS[page.m]} {page.y}
        </h1>
        <Button size="large" icon={<ArrowRightOutlined />} onClick={next} />
      </header>
      <Spin spinning={state !== 'LOADED'} size="large">
        <Form layout="horizontal" labelCol={{ span: 8 }} colon={false}>
          {formFields.map((field) => (
            <Form.Item
              key={field.slot}
              label={`${field.day} ${field.d} ${field.month}`}
              className={
                field.day === 'Zaterdag' || field.day === 'Zondag'
                  ? classes.weekend
                  : ''
              }
            >
              <InputNumber
                disabled={page.m === M && field.d < D}
                value={
                  slots?.[field.slot] !== undefined
                    ? slots[field.slot]
                    : settings?.perDag?.value || -1
                }
                onChange={(value) => setSlot(field.slot, value as number)}
                onBlur={saveSlots}
              />
            </Form.Item>
          ))}
        </Form>
      </Spin>
    </Text>
  );
};

interface Page {
  m: number;
  y: number;
}

interface Field {
  slot: string;
  day: string;
  month: string;
  d: number;
  m: number;
  y: number;
}
