import { FC } from 'react';
import { ManageItem } from '../components/ManageItem';
import { NewAccu } from '../components/NewAccu';
import { Text } from '../components/Text';
import { useCatalog } from '../hooks/useCatalog';

export const Manage: FC = () => {
  const { catalog } = useCatalog();

  return (
    <Text>
      <NewAccu />
      {catalog.map((item) => (
        <ManageItem item={item} key={item.id} />
      ))}
    </Text>
  );
};
