import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space
} from 'antd';
import axios from 'axios';
import { FC, Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { Text } from '../components/Text';
import { checkoutState } from '../store';
import classes from './checkout.module.css';

export const Checkout: FC = () => {
  const checkout = useSnapshot(checkoutState);
  const [filled, setFilled] = useState(false);

  if (checkout.products.length === 0) return <Redirect to="/producten" />;

  const initialValues = {
    name: checkout.name,
    email: checkout.email,
    phone: checkout.phone,
    confirm: checkout.email,
    address: checkout.address,
    postcode: checkout.postcode,
    city: checkout.city,
    terms: checkout.terms,
    shipment: checkout.shipment
  };

  function onFinish(data: Data) {
    checkoutState.name = data.name;
    checkoutState.email = data.email;
    checkoutState.phone = data.phone;
    checkoutState.address = data.address;
    checkoutState.postcode = data.postcode;
    checkoutState.city = data.city;
    checkoutState.terms = data.terms;
    checkoutState.shipment = data.shipment;
    setFilled(true);
  }

  async function handleCheckout() {
    document.querySelectorAll('button').forEach((btn) => (btn.disabled = true));
    const { data } = await axios.post('/api/order', checkout);
    window.location.href = `/api/order?uuid=${data.uuid}`;
  }

  return (
    <Text>
      <h1>Bestelling afronden</h1>
      {!checkout.spoed && (
        <Row gutter={[16, 16]}>
          <Col>
            <p style={{ marginTop: '-1rem' }}>
              Revisiedatum:{' '}
              <i>
                {checkout.date.d}-{checkout.date.m}-{checkout.date.y}
              </i>
            </p>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        {checkout.products.map((product) => (
          <Fragment key={product.name}>
            <Col span={18}>{product.name}</Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              € {product.price}
            </Col>
          </Fragment>
        ))}
        <Col span={18}>
          <strong>Totaal:</strong>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <strong>
            €{' '}
            {checkout.products.reduce<number>(
              (total, product) => total + product.price,
              0
            )}
          </strong>
        </Col>
      </Row>
      <Divider />
      <h2>Uw Gegevens</h2>
      {filled ? (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={6} className={classes.title}>
            <strong>Naam:</strong>
          </Col>
          <Col xs={24} sm={18}>
            {checkout.name}
          </Col>
          <Col xs={24} sm={6} className={classes.title}>
            <strong>Telefoon:</strong>
          </Col>
          <Col xs={24} sm={18}>
            {checkout.phone}
          </Col>
          <Col xs={24} sm={6} className={classes.title}>
            <strong>E-mail:</strong>
          </Col>
          <Col xs={24} sm={18}>
            {checkout.email}
          </Col>
          <Col xs={24} sm={6} className={classes.title}>
            <strong>Adres:</strong>
          </Col>
          <Col xs={24} sm={18}>
            {checkout.address}
            <br />
            {checkout.postcode} {checkout.city}
          </Col>
          <Col xs={24} sm={6} className={classes.title}>
            <strong>Verzending:</strong>
          </Col>
          <Col xs={24} sm={18}>
            {checkout.shipment === 'POST' && 'Ik verzend de accu per post'}
            {checkout.shipment === 'INSTORE' &&
              'Ik breng de accu langs op afspraak'}
            {checkout.shipment === 'INSTORECASH' &&
              'Ik breng de accu langs op afspraak en betaal bij aflevering'}
          </Col>
          <Col xs={24} sm={6} className={classes.title}>
            <strong>Algemene voorwaarden:</strong>
          </Col>
          <Col xs={24} sm={18}>
            <Checkbox disabled checked={checkout.terms}>
              {!checkout.terms && 'Niet '}Geaccepteerd
            </Checkbox>
          </Col>
          <Col xs={24} sm={{ offset: 6, span: 18 }}>
            <Space>
              <Button size="large" type="primary" onClick={handleCheckout}>
                {checkout.shipment === 'INSTORECASH'
                  ? 'Bestellen'
                  : 'Afrekenen'}
              </Button>
              <Button size="large" onClick={() => setFilled(false)}>
                Wijzigen
              </Button>
            </Space>
          </Col>
        </Row>
      ) : (
        <Form
          name="checkout"
          layout="horizontal"
          colon={false}
          requiredMark={false}
          labelCol={{ span: 6 }}
          initialValues={initialValues}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Naam"
            rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { required: true, message: 'Dit veld in verplicht.' },
              { type: 'email', message: 'Dit is geen geldig e-mailadres.' }
            ]}
            hasFeedback
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Bevestig E-mail"
            dependencies={['email']}
            hasFeedback
            rules={[
              { required: true, message: 'Dit veld in verplicht.' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        'De opgegeven e-mail adressen zijn niet hetzelfde.'
                      )
                    );
                  }
                }
              })
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Telefoon"
            rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          >
            <Input placeholder="06 12345678" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Adres"
            rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          >
            <Input placeholder="Straat + huisnummer" />
          </Form.Item>
          <Form.Item
            name="postcode"
            label="Postcode"
            rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          >
            <Input placeholder="1234 AB" maxLength={7} style={{ width: 80 }} />
          </Form.Item>
          <Form.Item
            name="city"
            label="Woonplaats"
            rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="shipment"
            label="Verzending"
            rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
            extra={
              <>
                U ontvangt de verzend instructies per e-mail. Voor het
                langsbrengen nemen wij contact met u op voor een afspraak. Onze
                gegevens zijn tevens terug te vinden op de{' '}
                <Link to="/contact" target="_blank">
                  contact pagina
                </Link>
                .
              </>
            }
          >
            <Select>
              <Select.Option disabled={checkoutState.spoed} value="POST">
                Per post (met vooruitbetaling)
                {checkoutState.spoed && ' (niet mogelijk bij spoedopdracht)'}
              </Select.Option>
              <Select.Option value="INSTORE">
                Langsbrengen op afspraak (met vooruitbetaling)
              </Select.Option>
              <Select.Option value="INSTORECASH">
                Langsbrengen op afspraak (betalen bij aflevering)
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="terms"
            label="Voorwaarden"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'U dient akkoord te gaan met de algemene voorwaarden om gebruik te maken van onze diensten.'
                        )
                      )
              }
            ]}
          >
            <Checkbox>
              Ik ga akkoord met de{' '}
              <Link to="/voorwaarden" target="_blank">
                algemene voorwaarden
              </Link>
              .
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Row>
              <Col xs={{ span: 24 }} sm={{ offset: 6, span: 18 }}>
                <Button type="primary" htmlType="submit">
                  Volgende
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
    </Text>
  );
};

interface Data {
  name: string;
  email: string;
  phone: string;
  address: string;
  postcode: string;
  city: string;
  terms: boolean;
  shipment: 'POST' | 'INSTORE';
}
