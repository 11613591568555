import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Spin
} from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import { useSnapshot } from 'valtio';
import { accusState, authState } from '../store';
import classes from './newAccu.module.css';

export const NewAccu: FC = () => {
  const { jwt } = useSnapshot(authState);
  const [showForm, setShowForm] = useState(false);
  const [state, setState] = useState<State>('READY');

  const onFinish = async (data: Data) => {
    setState('PENDING');
    try {
      const result = await axios.post('/api/accus', data, {
        headers: { Authorization: `Bearer ${jwt}` }
      });
      accusState.accus[result.data.id] = result.data;
      setShowForm(false);
      setState('READY');
    } catch {
      setState('ERROR');
    }
  };

  return (
    <>
      {!showForm && (
        <div style={{ textAlign: 'right', marginTop: 12 }}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowForm(true)}
          >
            Accu toevoegen
          </Button>
        </div>
      )}
      {showForm && (
        <Row gutter={[16, 16]}>
          <Col xs={0} md={8} className={classes.pic}></Col>
          <Col xs={24} md={16}>
            <h2>Accu toevoegen</h2>
            {state === 'ERROR' && (
              <Alert
                message="Er is een fout opgetreden bij het opslaan."
                type="error"
                showIcon
              />
            )}
            <Spin spinning={state === 'PENDING'} size="large">
              <Form
                name="newAccu"
                layout="vertical"
                onFinish={onFinish}
                requiredMark={false}
              >
                <Form.Item
                  name="name"
                  label="Naam"
                  rules={[
                    { required: true, message: 'Dit veld in verplicht.' }
                  ]}
                >
                  <Input autoFocus />
                </Form.Item>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="voltage"
                      label="Voltage"
                      rules={[
                        { required: true, message: 'Dit veld in verplicht.' }
                      ]}
                    >
                      <InputNumber addonAfter="V" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="capacity"
                      label="Originele capaciteit"
                      rules={[
                        { required: true, message: 'Dit veld in verplicht.' }
                      ]}
                    >
                      <InputNumber addonAfter="Ah" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="description" label="Omschrijving">
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Opslaan
                    </Button>
                    <Button htmlType="reset" onClick={() => setShowForm(false)}>
                      Annuleren
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>
      )}
      <Divider />
    </>
  );
};

type State = 'READY' | 'PENDING' | 'DONE' | 'ERROR';

interface Data {
  name: string;
  voltage: number;
  capacity: number;
  description: string;
}
