export function sortByKey<T = Record<string, any>>(key: keyof T) {
  return (a: T, b: T): number => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };
}

export function sortByNumericKey<T = Record<string, any>>(key: keyof T) {
  return (a: T, b: T): number => {
    if (Number(a[key]) < Number(b[key])) return -1;
    if (Number(a[key]) > Number(b[key])) return 1;
    return 0;
  };
}
