import { Route, Switch } from 'react-router-dom';
import { Container } from './components/Container';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Nav } from './components/Nav';
import { RequireAuth } from './components/RequireAuth';
import { useCatalog } from './hooks/useCatalog';
import { Bedankt } from './pages/Bedankt';
import { Checkout } from './pages/Checkout';
import { Contact } from './pages/Contact';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { Manage } from './pages/Manage';
import { ManualOrder } from './pages/ManualOrder';
import { Orders } from './pages/Orders';
import { Products } from './pages/Products';
import { Settings } from './pages/Settings';
import { Slots } from './pages/Slots';
import { Voorwaarden } from './pages/Voorwaarden';

function App() {
  useCatalog();

  return (
    <Container>
      <Header />
      <Nav />
      <main>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/bedankt">
            <Bedankt />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/producten/:id?">
            <Products />
          </Route>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/voorwaarden">
            <Voorwaarden />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/manage">
            <RequireAuth>
              <Manage />
            </RequireAuth>
          </Route>
          <Route path="/orders">
            <RequireAuth>
              <Orders />
            </RequireAuth>
          </Route>
          <Route path="/slots">
            <RequireAuth>
              <Slots />
            </RequireAuth>
          </Route>
          <Route path="/settings">
            <RequireAuth>
              <Settings />
            </RequireAuth>
          </Route>
          <Route path="/create">
            <RequireAuth>
              <ManualOrder />
            </RequireAuth>
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Container>
  );
}

export default App;
