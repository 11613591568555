import { SearchOutlined } from '@ant-design/icons';
import { Alert, Col, Input, Row } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useCatalog } from '../hooks/useCatalog';
import { ProductCard } from './ProductCard';

export const AllProducts: FC = () => {
  const { catalog } = useCatalog();
  const [query, setQuery] = useState<string>('');

  const nonEmptyCatalog = useMemo(
    () => catalog.filter((item) => item.products.length > 0),
    [catalog]
  );
  const filteredCatalog = useMemo(() => {
    return query === ''
      ? nonEmptyCatalog
      : nonEmptyCatalog.filter(
          (item) =>
            item.name.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase())
        );
  }, [nonEmptyCatalog, query]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={24}>
          <Input
            allowClear
            value={query}
            onChange={(ev) => setQuery(ev.target.value)}
            placeholder="Zoeken..."
            style={{ borderRadius: 32 }}
            prefix={<SearchOutlined />}
            size="large"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        {filteredCatalog.map((item) => (
          <Col xs={24} sm={12} md={8} key={item.id}>
            <ProductCard product={item} />
          </Col>
        ))}
        {filteredCatalog.length === 0 && (
          <Col span={24}>
            <Alert
              message="Er zijn geen resultaten gevonden."
              type="info"
              showIcon
            />
          </Col>
        )}
      </Row>
    </>
  );
};
