import { Card, Col, Row } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { CatalogEntry } from '../store';
import classes from './productCard.module.css';

export const ProductCard: FC<{ product: CatalogEntry }> = ({ product }) => {
  const history = useHistory();

  return (
    <Card
      hoverable
      onClick={() => history.push(`/producten/${product.id}`)}
      cover={
        <div
          className={classes.cover}
          style={{ backgroundImage: `url(/pics/${product.id}.jpg)` }}
        />
      }
    >
      <Card.Meta
        title={
          <Row align="middle">
            <Col flex="auto">{product.name}</Col>
            <Col className={classes.v}>{product.voltage}V</Col>
          </Row>
        }
      />
    </Card>
  );
};
