import {
  CloseOutlined,
  DeleteOutlined,
  EditFilled,
  SaveFilled,
  ThunderboltFilled
} from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row } from 'antd';
import { FC, useState } from 'react';
import { deleteProduct, Product, saveProduct } from '../store';
import classes from './manageProduct.module.css';

export const ManageProduct: FC<{ product: Product }> = ({ product }) => {
  const [showForm, setShowForm] = useState(false);

  const onFinish = (data: Data) => {
    saveProduct({ ...product, ...data });
    setShowForm(false);
  };

  function handleDelete() {
    if (
      // eslint-disable-next-line
      confirm('Weet u zeker dat u dit product wilt verwijderen?')
    )
      deleteProduct(product);
  }

  return (
    <section className={classes.container}>
      {!showForm && (
        <Row gutter={[16, 16]} className={classes.hContainer}>
          <Col flex="auto">
            <p style={{ margin: 0, marginTop: 4 }}>
              <ThunderboltFilled /> {product.capacity}Ah voor € {product.price}{' '}
              <small
                style={{
                  verticalAlign: 'middle',
                  marginLeft: 12,
                  display: 'inline-block',
                  marginTop: -4
                }}
              >
                (Werklast: {product.slots})
              </small>
            </p>
          </Col>
          <Col flex="none">
            <Button
              icon={<EditFilled />}
              type="link"
              className={classes.hItem}
              onClick={() => setShowForm(true)}
            />
            <Button
              icon={<DeleteOutlined />}
              type="link"
              className={classes.hItem}
              danger
              onClick={handleDelete}
            />
          </Col>
        </Row>
      )}
      {showForm && (
        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          initialValues={product}
        >
          <Row gutter={[16, 16]} wrap={false}>
            <Col flex={1}>
              <Form.Item
                name="capacity"
                label="Capaciteit"
                rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
              >
                <InputNumber
                  autoFocus
                  addonAfter="Ah"
                  style={{ maxWidth: 200 }}
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                name="price"
                label="Prijs"
                rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
              >
                <InputNumber prefix="€" />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                name="slots"
                label="Werklast"
                rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col flex="none" style={{ marginTop: 30 }}>
              <Button icon={<SaveFilled />} type="primary" htmlType="submit" />
              <Button
                icon={<CloseOutlined />}
                type="link"
                danger
                onClick={() => setShowForm(false)}
              />
            </Col>
          </Row>
        </Form>
      )}
    </section>
  );
};

interface Data {
  capacity: number;
  slots: number;
  price: number;
}
