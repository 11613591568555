import {
  CloseOutlined,
  EditOutlined,
  SaveFilled,
  UploadOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Upload
} from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { FC, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSnapshot } from 'valtio';
import { sortByNumericKey } from '../lib';
import { CatalogEntry, authState, saveAccu } from '../store';
import { ManageProduct } from './ManageProduct';
import { NewProduct } from './NewProduct';
import classes from './manageItem.module.css';

export const ManageItem: FC<{ item: CatalogEntry }> = ({ item }) => {
  const { jwt } = useSnapshot(authState);
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState(item.name);
  const [editDescription, setEditDescription] = useState(false);
  const [description, setDescription] = useState(item.description);
  const [i, setI] = useState(0);

  const products = useMemo(
    () => [...item.products].sort(sortByNumericKey('capacity')),
    [item.products]
  );

  function cancelName() {
    setEditName(false);
    setName(item.name);
  }

  function cancelDescription() {
    setEditDescription(false);
    setDescription(item.description);
  }

  function saveName() {
    setEditName(false);
    saveAccu({ ...item, name });
  }

  function saveDescription() {
    setEditDescription(false);
    saveAccu({ ...item, description });
  }

  function saveVoltage(voltage: number | null) {
    saveAccu({ ...item, voltage: voltage as number });
  }

  function saveCapacity(capacity: number | null) {
    saveAccu({ ...item, capacity: capacity as number });
  }

  function beforeUpload(file: RcFile) {
    const isJpg = file.type === 'image/jpeg';
    return isJpg;
  }

  function onChangeUpload(status: UploadChangeParam) {
    if (status.file.status === 'done') {
      setI((i) => i + 1);
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={8}
          className={classes.pic}
          style={{ backgroundImage: `url(/pics/${item.id}.jpg?i=${i})` }}
        >
          <Upload
            name="image"
            accept="image/jpeg"
            action="/api/upload"
            data={{ id: item.id }}
            beforeUpload={beforeUpload}
            onChange={onChangeUpload}
            showUploadList={false}
            headers={{ Authorization: `Bearer ${jwt}` }}
          >
            <Button
              icon={<UploadOutlined />}
              size="large"
              type="primary"
              shape={'circle'}
            />
          </Upload>
        </Col>
        <Col xs={24} sm={16}>
          {!editName && (
            <h2 className={classes.hContainer}>
              {item.name}{' '}
              <Button
                className={classes.hItem}
                type="text"
                icon={<EditOutlined />}
                onClick={() => setEditName(true)}
              />
            </h2>
          )}
          {editName && (
            <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
              <Col flex="auto">
                <Input
                  autoFocus
                  size="large"
                  value={name}
                  onChange={(ev) => setName(ev.target.value)}
                />
              </Col>
              <Col>
                <Button.Group size="large">
                  <Button
                    icon={<SaveFilled />}
                    type="primary"
                    disabled={name === item.name}
                    onClick={saveName}
                  />
                  <Button
                    icon={<CloseOutlined />}
                    type="link"
                    danger
                    onClick={cancelName}
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          <Form layout="horizontal" colon={false} labelCol={{ span: 7 }}>
            <Form.Item label="Voltage">
              <InputNumber
                style={{ width: 104 }}
                value={item.voltage}
                addonAfter="V"
                onChange={saveVoltage}
              />
            </Form.Item>
            <Form.Item label="Originele capaciteit">
              <InputNumber
                style={{ width: 104 }}
                value={item.capacity}
                addonAfter="Ah"
                onChange={saveCapacity}
              />
            </Form.Item>
          </Form>
          <div className={`${classes.hContainer} ${classes.description}`}>
            {!editDescription && (
              <>
                <ReactMarkdown>{item.description}</ReactMarkdown>
                <Button
                  className={classes.hItem}
                  type="default"
                  icon={<EditOutlined />}
                  size="large"
                  onClick={() => setEditDescription(true)}
                />
              </>
            )}
            {editDescription && (
              <>
                <Input.TextArea
                  autoSize
                  value={description}
                  onChange={(ev) => setDescription(ev.target.value)}
                />
                <br />
                <br />
                <Space>
                  <Button
                    type="primary"
                    icon={<SaveFilled />}
                    onClick={saveDescription}
                  >
                    Opslaan
                  </Button>
                  <Button
                    type="link"
                    danger
                    icon={<CloseOutlined />}
                    onClick={cancelDescription}
                  >
                    Annuleren
                  </Button>
                </Space>
              </>
            )}
          </div>
          {!editDescription &&
            products.map((product) => (
              <ManageProduct product={product} key={product.id} />
            ))}
          {!editDescription && <NewProduct item={item} />}
        </Col>
      </Row>
      <Divider />
    </>
  );
};
