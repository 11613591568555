import { Form, InputNumber, Spin } from 'antd';
import { FC } from 'react';
import { Text } from '../components/Text';
import { useSettings } from '../hooks/useSettings';
import { saveSettings, setSetting } from '../store';

export const Settings: FC = () => {
  const { state, settings } = useSettings();

  return (
    <Text>
      <h1>Instellingen</h1>
      <Spin spinning={state !== 'LOADED'} size="large">
        <Form layout="horizontal" labelCol={{ span: 6 }} colon={false}>
          <Form.Item label="Accu's per dag">
            <InputNumber
              value={settings?.perDag?.value || 5}
              onChange={
                settings?.perDag
                  ? (value) => setSetting(settings.perDag, value as number)
                  : undefined
              }
              onBlur={saveSettings}
            />
          </Form.Item>
          <Form.Item label="Garantie prijs">
            <InputNumber
              prefix="€"
              value={settings?.garantie?.value || 25}
              onChange={
                settings?.garantie
                  ? (value) => setSetting(settings.garantie, value as number)
                  : undefined
              }
              onBlur={saveSettings}
            />
          </Form.Item>
          <Form.Item label="Spoed prijs">
            <InputNumber
              prefix="€"
              value={settings?.spoed?.value || 25}
              onChange={
                settings?.spoed
                  ? (value) => setSetting(settings.spoed, value as number)
                  : undefined
              }
              onBlur={saveSettings}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Text>
  );
};
