import { FC } from 'react';
import { Text } from '../components/Text';

export const Voorwaarden: FC = () => (
  <Text>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus hendrerit
      velit consectetur condimentum semper. Vivamus congue vel nunc eu
      porttitor. Suspendisse pretium ex sit amet mi vulputate bibendum. Cras
      faucibus, risus eu suscipit volutpat, nulla diam congue urna, nec lacinia
      libero mi ac tortor. Mauris dapibus nulla dolor, eget dictum velit commodo
      a. Aliquam sed posuere lorem, ac cursus erat. Fusce sed aliquam justo.
      Mauris vel nunc hendrerit, efficitur elit eu, tristique justo.
    </p>
    <p>
      Morbi nulla urna, mattis imperdiet laoreet vitae, posuere in enim. Fusce
      turpis urna, eleifend ut ullamcorper nec, porttitor at erat. Phasellus sit
      amet purus vehicula, congue velit ut, luctus lacus. Integer eget accumsan
      ex. Suspendisse eget porttitor mi, eu vehicula arcu. Duis ullamcorper dui
      justo, mollis bibendum ex commodo non. Class aptent taciti sociosqu ad
      litora torquent per conubia nostra, per inceptos himenaeos. Etiam
      tincidunt vitae enim sed vestibulum. Sed tristique ullamcorper purus, non
      faucibus ante scelerisque ac. Maecenas vestibulum, leo nec hendrerit
      vehicula, orci neque faucibus tellus, eu maximus magna mi sit amet tellus.
      Nulla vel purus non est placerat venenatis. Etiam pharetra ligula a
      pulvinar sodales. In molestie tortor in convallis luctus.
    </p>
    <p>
      Quisque accumsan tristique felis et ornare. Donec mollis placerat magna
      eget sagittis. Praesent non felis at est sagittis maximus non ac dui.
      Vivamus nec sem quis ligula accumsan volutpat. Vestibulum at elit ac eros
      fermentum molestie. Aenean urna lectus, egestas nec ligula quis, semper
      placerat ligula. Sed nulla orci, dignissim ac nulla lobortis, faucibus
      porttitor arcu. Suspendisse rhoncus condimentum turpis fermentum congue.
    </p>
    <p>
      Etiam vel pulvinar libero, vel maximus felis. Cras tincidunt, sapien in
      rutrum semper, eros risus cursus lorem, nec tristique velit sapien et mi.
      Aliquam tempus sem in augue volutpat, in hendrerit neque vestibulum. Etiam
      placerat suscipit mauris, ac hendrerit nisl pretium non. Mauris congue et
      odio aliquam varius. Donec sodales tellus nunc, sit amet fermentum lacus
      porttitor id. Morbi convallis ipsum ligula, a imperdiet nisi venenatis a.
      Ut euismod dapibus sapien mollis feugiat. In hac habitasse platea
      dictumst. Praesent quis porttitor ex. Pellentesque interdum, odio sit amet
      semper molestie, dui tellus varius odio, sed cursus urna ligula vel nibh.
      Proin cursus id nisl at volutpat. Pellentesque nunc sapien, sagittis eget
      fringilla sed, volutpat quis odio. Proin non neque facilisis, vehicula
      sapien volutpat, congue neque.
    </p>
    <p>
      Morbi tellus neque, fringilla pretium nunc venenatis, maximus mattis
      lorem. Donec semper commodo nisl et sollicitudin. Curabitur mollis felis
      at odio hendrerit, nec consequat ipsum consectetur. Integer lorem purus,
      dignissim ut blandit sed, imperdiet in dui. Morbi mauris ipsum, suscipit
      nec dignissim at, vehicula auctor ex. In leo leo, vestibulum eget nulla
      non, posuere blandit diam. Ut dictum a dolor ac vehicula. Nulla sed tortor
      lorem. Nam sed rhoncus sem. Fusce faucibus ligula ac mauris eleifend, et
      dictum mi vulputate. Morbi quis cursus dui, eget fermentum purus. Proin
      efficitur magna et magna malesuada dictum.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus hendrerit
      velit consectetur condimentum semper. Vivamus congue vel nunc eu
      porttitor. Suspendisse pretium ex sit amet mi vulputate bibendum. Cras
      faucibus, risus eu suscipit volutpat, nulla diam congue urna, nec lacinia
      libero mi ac tortor. Mauris dapibus nulla dolor, eget dictum velit commodo
      a. Aliquam sed posuere lorem, ac cursus erat. Fusce sed aliquam justo.
      Mauris vel nunc hendrerit, efficitur elit eu, tristique justo.
    </p>
    <p>
      Morbi nulla urna, mattis imperdiet laoreet vitae, posuere in enim. Fusce
      turpis urna, eleifend ut ullamcorper nec, porttitor at erat. Phasellus sit
      amet purus vehicula, congue velit ut, luctus lacus. Integer eget accumsan
      ex. Suspendisse eget porttitor mi, eu vehicula arcu. Duis ullamcorper dui
      justo, mollis bibendum ex commodo non. Class aptent taciti sociosqu ad
      litora torquent per conubia nostra, per inceptos himenaeos. Etiam
      tincidunt vitae enim sed vestibulum. Sed tristique ullamcorper purus, non
      faucibus ante scelerisque ac. Maecenas vestibulum, leo nec hendrerit
      vehicula, orci neque faucibus tellus, eu maximus magna mi sit amet tellus.
      Nulla vel purus non est placerat venenatis. Etiam pharetra ligula a
      pulvinar sodales. In molestie tortor in convallis luctus.
    </p>
    <p>
      Quisque accumsan tristique felis et ornare. Donec mollis placerat magna
      eget sagittis. Praesent non felis at est sagittis maximus non ac dui.
      Vivamus nec sem quis ligula accumsan volutpat. Vestibulum at elit ac eros
      fermentum molestie. Aenean urna lectus, egestas nec ligula quis, semper
      placerat ligula. Sed nulla orci, dignissim ac nulla lobortis, faucibus
      porttitor arcu. Suspendisse rhoncus condimentum turpis fermentum congue.
    </p>
    <p>
      Etiam vel pulvinar libero, vel maximus felis. Cras tincidunt, sapien in
      rutrum semper, eros risus cursus lorem, nec tristique velit sapien et mi.
      Aliquam tempus sem in augue volutpat, in hendrerit neque vestibulum. Etiam
      placerat suscipit mauris, ac hendrerit nisl pretium non. Mauris congue et
      odio aliquam varius. Donec sodales tellus nunc, sit amet fermentum lacus
      porttitor id. Morbi convallis ipsum ligula, a imperdiet nisi venenatis a.
      Ut euismod dapibus sapien mollis feugiat. In hac habitasse platea
      dictumst. Praesent quis porttitor ex. Pellentesque interdum, odio sit amet
      semper molestie, dui tellus varius odio, sed cursus urna ligula vel nibh.
      Proin cursus id nisl at volutpat. Pellentesque nunc sapien, sagittis eget
      fringilla sed, volutpat quis odio. Proin non neque facilisis, vehicula
      sapien volutpat, congue neque.
    </p>
    <p>
      Morbi tellus neque, fringilla pretium nunc venenatis, maximus mattis
      lorem. Donec semper commodo nisl et sollicitudin. Curabitur mollis felis
      at odio hendrerit, nec consequat ipsum consectetur. Integer lorem purus,
      dignissim ut blandit sed, imperdiet in dui. Morbi mauris ipsum, suscipit
      nec dignissim at, vehicula auctor ex. In leo leo, vestibulum eget nulla
      non, posuere blandit diam. Ut dictum a dolor ac vehicula. Nulla sed tortor
      lorem. Nam sed rhoncus sem. Fusce faucibus ligula ac mauris eleifend, et
      dictum mi vulputate. Morbi quis cursus dui, eget fermentum purus. Proin
      efficitur magna et magna malesuada dictum.
    </p>
  </Text>
);
