import { Alert, Button, Form, Input, Spin } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';

export const EmailForm: FC = () => {
  const [state, setState] = useState<State>('READY');
  const [initialValues, setInitialValues] = useState<Partial<Data>>({});

  const onFinish = async (data: Data) => {
    setState('PENDING');
    try {
      await axios.post('/api/mail.php', data);
      setState('DONE');
    } catch {
      setState('ERROR');
      setInitialValues(data);
    }
  };

  if (state === 'DONE')
    return (
      <p>
        Bedankt voor uw bericht. We nemen zo spoedig mogelijk contact met u op.
      </p>
    );

  if (state === 'ERROR') {
    return (
      <Alert
        message={
          <>
            Er is een fout opgetreden bij het verzenden van de e-mail.{' '}
            <a
              href="#/contact"
              onClick={(ev) => {
                ev.preventDefault();
                setState('READY');
              }}
            >
              Probeer het opnieuw
            </a>{' '}
            of <a href="mailto:info@helderaccuservice.nl">verstuur</a> direct
            een e-mail.
          </>
        }
        type="error"
      />
    );
  }

  return (
    <Spin spinning={state === 'PENDING'} size="large">
      <Form
        name="contact"
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label="Naam"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[{ required: true, message: 'Dit veld is verplicht.' }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="message"
          label="Bericht"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Verzenden
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

interface Data {
  name: string;
  email: string;
  message: string;
}

type State = 'READY' | 'PENDING' | 'DONE' | 'ERROR';
