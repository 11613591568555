import { CloseOutlined, EditOutlined, SaveFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space
} from 'antd';
import axios from 'axios';
import { FC, Fragment, useEffect, useState } from 'react';
import { Text } from '../components/Text';
import { useOrders } from '../hooks/useOrders';
import {
  Order,
  TrackAndTraceData,
  sendInvoice as _sendInvoice,
  authState,
  sendConfirm,
  sendTrackAndTrace,
  setOrders
} from '../store';
import classes from './orders.module.css';

function sortByKey<T = Record<string, any>>(key: keyof T) {
  return (a: T, b: T): number => {
    if (key === 'id')
      return `${a[key]}`.localeCompare(`${b[key]}`, undefined, {
        numeric: true,
        sensitivity: 'base'
      });

    if (key === 'date') {
      if ((a as any).data.date.y !== (b as any).data.date.y)
        return (a as any).data.date.y - (b as any).data.date.y;
      if ((a as any).data.date.m !== (b as any).data.date.m)
        return (a as any).data.date.m - (b as any).data.date.m;
      return (a as any).data.date.d - (b as any).data.date.d;
    }

    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };
}

export const Orders: FC = () => {
  const { orders } = useOrders();
  const [disabledConfirm, setDisabledConfirm] = useState<string[]>([]);
  const [disabledInvoice, setDisabledInvoice] = useState<string[]>([]);
  const [trackAndTraceData, setTrackAndTraceData] = useState<TrackAndTraceData>(
    {} as TrackAndTraceData
  );
  const [trackAndTrackModal, setTrackAndTraceModal] = useState<boolean>(false);

  const [editOrder, setEditOrder] = useState<null | string>(null);
  const [editValue, setEditValue] = useState('');

  const [orderField, setOrderField] = useState<string>('id');
  const [orderNumber, setOrderNumber] = useState<number>(25);

  useEffect(() => {
    if (editOrder !== null) {
      const order = orders.filter((order) => order.uuid === editOrder)[0];
      setEditValue(JSON.stringify(order.data.products, null, 2));
    }
    if (editOrder == null) {
      setEditValue('');
    }
  }, [editOrder, orders]);

  async function saveOrderValue() {
    try {
      const parsedValue = JSON.parse(editValue);
      let orderToSave: Order;
      setOrders(
        orders.map((order) => {
          if (order.uuid === editOrder) {
            orderToSave = {
              ...order,
              data: {
                ...order.data,
                products: parsedValue
              }
            };
            return orderToSave;
          } else {
            return order;
          }
        })
      );
      axios.put(`/api/order?uuid=${orderToSave!.uuid}`, orderToSave!.data, {
        headers: { Authorization: `Bearer ${authState.jwt}` }
      });
      setEditOrder(null);
    } catch {
      alert('Ongeldige JSON');
    }
  }

  async function resendConfirm(id: string) {
    // eslint-disable-next-line no-restricted-globals
    const ok = confirm(
      'Weet u zeker dat u de bevestiging nogmaals wilt verzenden?'
    );
    if (!ok) return;
    setDisabledConfirm((v) => [...v, id]);
    await sendConfirm(id);
    setDisabledConfirm((v) => v.filter((value) => value !== id));
  }

  async function sendInvoice(id: string) {
    // eslint-disable-next-line no-restricted-globals
    const ok = confirm('Weet u zeker dat u de factuur wilt verzenden?');
    if (!ok) return;
    setDisabledInvoice((v) => [...v, id]);
    await _sendInvoice(id);
    setDisabledInvoice((v) => v.filter((value) => value !== id));
  }

  const openTrackAndTraceModal = (order: Order) => {
    setTrackAndTraceData({
      uuid: order.uuid,
      order,
      d: 'NL',
      b: '',
      p: order.data.postcode.replaceAll(' ', ''),
      l: 'NL'
    });
    setTrackAndTraceModal(true);
  };

  const modalOk = async () => {
    setTrackAndTraceModal(false);
    await sendTrackAndTrace(trackAndTraceData);
  };

  const modalCancel = () => {
    setTrackAndTraceModal(false);
  };

  return (
    <Text>
      <Row>
        <Col span={24}>
          Sorteer op:{' '}
          <div className={classes.sel}>
            <Select
              value={orderField}
              onChange={(value) => setOrderField(value)}
            >
              <Select.Option value="id">Id</Select.Option>
              <Select.Option value="date">Datum</Select.Option>
            </Select>
          </div>{' '}
          Aantal:{' '}
          <InputNumber
            value={orderNumber}
            onChange={(value) => setOrderNumber(value as number)}
          />
          <hr />
        </Col>
      </Row>
      <Modal
        title="Verzend track & trace code"
        open={trackAndTrackModal}
        onOk={modalOk}
        onCancel={modalCancel}
        okText="Verzend!"
        cancelText="Annuleren"
      >
        <Row gutter={[16, 16]}>
          <Col className={classes.title} xs={24} sm={6}>
            <strong>Naam:</strong>
          </Col>
          <Col xs={24} sm={18}>
            {trackAndTraceData?.order?.data.name}
          </Col>
          <Col className={classes.title2} xs={24} sm={6}>
            <strong>Bestemming:</strong>
          </Col>
          <Col xs={24} sm={18}>
            <Input
              value={trackAndTraceData?.d}
              onChange={(ev) =>
                setTrackAndTraceData((d) => ({ ...d, d: ev.target.value }))
              }
            />
          </Col>
          <Col className={classes.title2} xs={24} sm={6}>
            <strong>Barcode:</strong>
          </Col>
          <Col xs={24} sm={18}>
            <Input
              value={trackAndTraceData?.b}
              onChange={(ev) =>
                setTrackAndTraceData((d) => ({ ...d, b: ev.target.value }))
              }
            />
          </Col>
          <Col className={classes.title2} xs={24} sm={6}>
            <strong>Postcode:</strong>
          </Col>
          <Col xs={24} sm={18}>
            <Input
              value={trackAndTraceData?.p}
              onChange={(ev) =>
                setTrackAndTraceData((d) => ({ ...d, p: ev.target.value }))
              }
            />
          </Col>
          <Col className={classes.title2} xs={24} sm={6}>
            <strong>Taal:</strong>
          </Col>
          <Col xs={24} sm={18}>
            <Select
              style={{ width: '100%' }}
              value={trackAndTraceData.l}
              onChange={(v) => setTrackAndTraceData((d) => ({ ...d, l: v }))}
              options={[
                { value: 'CN', label: 'CN' },
                { value: 'DE', label: 'DE' },
                { value: 'EN', label: 'EN' },
                { value: 'ES', label: 'ES' },
                { value: 'FR', label: 'FR' },
                { value: 'IT', label: 'IT' },
                { value: 'NL', label: 'NL' }
              ]}
            />
          </Col>
        </Row>
      </Modal>
      {[...orders]
        .sort(sortByKey(orderField as any))
        .reverse()
        .slice(0, orderNumber)
        .map((order) => (
          <Fragment key={order.uuid}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={18}>
                <h2>Order #{order.id}</h2>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={6} className={classes.title}>
                    <strong>Naam:</strong>
                  </Col>
                  <Col xs={24} sm={18}>
                    {order.data.name}
                  </Col>
                  <Col xs={24} sm={6} className={classes.title}>
                    <strong>Telefoon:</strong>
                  </Col>
                  <Col xs={24} sm={18}>
                    {order.data.phone}
                  </Col>
                  <Col xs={24} sm={6} className={classes.title}>
                    <strong>E-mail:</strong>
                  </Col>
                  <Col xs={24} sm={18}>
                    {order.data.email}
                  </Col>
                  <Col xs={24} sm={6} className={classes.title}>
                    <strong>Adres:</strong>
                  </Col>
                  <Col xs={24} sm={18}>
                    {order.data.address}
                    <br />
                    {order.data.postcode} {order.data.city}
                  </Col>
                  <Col xs={24} sm={6} className={classes.title}>
                    <strong>Datum:</strong>
                  </Col>
                  <Col xs={24} sm={18}>
                    {order.data.date.d}-{order.data.date.m}-{order.data.date.y}
                    <br />
                  </Col>
                  <Col xs={24} sm={6} className={classes.title}>
                    <strong>Verzending:</strong>
                  </Col>
                  <Col xs={24} sm={18}>
                    {order.data.shipment === 'POST' &&
                      'Ik verzend de accu per post'}
                    {order.data.shipment === 'INSTORE' &&
                      'Ik breng de accu langs op afspraak'}
                  </Col>
                  <Col
                    xs={24}
                    sm={18}
                    className={[classes.bestelling, classes.hContainer].join(
                      ' '
                    )}
                  >
                    <h3>
                      Bestelling{' '}
                      {editOrder !== order.uuid && (
                        <Button
                          className={classes.hItem}
                          type="text"
                          icon={<EditOutlined />}
                          onClick={() => setEditOrder(order.uuid)}
                        />
                      )}
                    </h3>
                    {editOrder === order.uuid && (
                      <Fragment>
                        <Input.TextArea
                          autoSize
                          value={editValue}
                          onChange={(ev) => setEditValue(ev.target.value)}
                        />
                        <br />
                        <br />
                        <Space>
                          <Button
                            type="primary"
                            icon={<SaveFilled />}
                            onClick={saveOrderValue}
                          >
                            Opslaan
                          </Button>
                          <Button
                            type="link"
                            danger
                            icon={<CloseOutlined />}
                            onClick={() => setEditOrder(null)}
                          >
                            Annuleren
                          </Button>
                        </Space>
                      </Fragment>
                    )}
                    {editOrder !== order.uuid && (
                      <Row gutter={[16, 16]}>
                        {order.data.products.map((product) => (
                          <Fragment key={product.name}>
                            <Col span={18}>{product.name}</Col>
                            <Col span={6} style={{ textAlign: 'right' }}>
                              € {product.price}
                            </Col>
                          </Fragment>
                        ))}
                        <Col span={18}>
                          <strong>Totaal:</strong>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                          <strong>
                            €{' '}
                            {order.data.products.reduce<number>(
                              (total, product) => total + product.price,
                              0
                            )}
                          </strong>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={6}>
                <h2>Acties</h2>
                <Space direction="vertical">
                  <Button
                    disabled={disabledConfirm.includes(order.uuid)}
                    block
                    onClick={() => resendConfirm(order.uuid)}
                  >
                    Verstuur Bevestiging
                  </Button>
                  <Button
                    disabled={trackAndTrackModal}
                    block
                    onClick={() => openTrackAndTraceModal(order as any)}
                  >
                    Verstuur Track & Trace
                  </Button>
                  <Button
                    disabled={disabledInvoice.includes(order.uuid)}
                    block
                    onClick={() => sendInvoice(order.uuid)}
                  >
                    Verstuur Factuur
                  </Button>
                </Space>
              </Col>
            </Row>
            <Divider />
          </Fragment>
        ))}
    </Text>
  );
};
