import { Menu } from 'antd';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { authState } from '../store';
import classes from './nav.module.css';

export const Nav: FC = () => {
  const { pathname } = useLocation();
  const page = `/${pathname.slice(1).split('/')[0]}`;

  const auth = useSnapshot(authState);

  return (
    <nav className={classes.nav}>
      <Menu mode="horizontal" selectedKeys={[page]}>
        <Menu.Item key="/">
          <Link to="/">Welkom</Link>
        </Menu.Item>
        <Menu.Item key="/producten">
          <Link to="/producten">Producten</Link>
        </Menu.Item>
        <Menu.Item key="/contact">
          <Link to="/contact">Contact</Link>
        </Menu.Item>
        {auth.jwt && (
          <>
            <Menu.Item key="/manage">
              <Link to="/manage">Beheer</Link>
            </Menu.Item>
            <Menu.Item key="/orders">
              <Link to="/orders">Bestellingen</Link>
            </Menu.Item>
            <Menu.Item key="/slots">
              <Link to="/slots">Beschikbaarheid</Link>
            </Menu.Item>
            <Menu.Item key="/settings">
              <Link to="/settings">Instellingen</Link>
            </Menu.Item>
            <Menu.Item key="/create">
              <Link to="/create">Order aanmaken</Link>
            </Menu.Item>
            <Menu.Item key="/logout">
              <Link to="/logout">Uitloggen</Link>
            </Menu.Item>
          </>
        )}
        {!auth.jwt && page === '/login' && (
          <Menu.Item key="/login">
            <Link to="/login">Inloggen</Link>
          </Menu.Item>
        )}
      </Menu>
    </nav>
  );
};
