import { FC } from 'react';
import { Text } from '../components/Text';

export const Home: FC = () => (
  <Text>
    <h1>Welkom</h1>
    <p>Welkom bij Helder Accu Service.</p>
    <p>
      Een nieuwe onderneming in Den Helder gericht op het reviseren van
      elektrische fiets-, e-scooter-, e-step en boosteraccu's.
    </p>
    <p>
      Wij zijn gespecialiseerd in het vernieuwen van fietsaccu's van de wat
      oudere modellen elektrische fiets (2009-2015). De fietsen zijn meestal nog
      in orde, maar aan de accu is te merken dat deze al op leeftijd is. De
      radius wordt steeds minder of er treden defecten op aan uw accu.
    </p>
    <p>
      Een nieuwe vervangende accu is over het algemeen vrij prijzig, revisie
      biedt een goed alternatief. U heeft op deze manier een gloednieuwe accu,
      alleen dan in het oude jasje.
    </p>
    <p>
      Onder producten kunt u zien welke accu's wij op dit moment allemaal
      reviseren.
    </p>
    <p>
      We breiden ons bestand dagelijks uit. Staat uw accu er (nog) niet tussen?
      Neem dan even contact met ons op, dan kijken we graag wat we voor u kunnen
      betekenen.
    </p>
    <p>Met vriendelijke groet,</p>
    <p>Team Helder Accu Service</p>
  </Text>
);
