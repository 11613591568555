import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Spin } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { Text } from '../components/Text';
import { authState } from '../store';

export const Login: FC = () => {
  const auth = useSnapshot(authState);
  const [state, setState] = useState<State>('READY');
  const [initialValues, setInitialValues] = useState<Partial<Data>>({});

  const onFinish = async (data: Data) => {
    setState('PENDING');
    try {
      const response = await axios.post('/api/auth.php', data);
      setState('DONE');
      authState.jwt = response.data.token;
    } catch {
      setState('ERROR');
      setInitialValues(data);
    }
  };

  if (state === 'DONE') return <Redirect to="/manage" />;

  if (auth.jwt) return <Redirect to="/manage" />;

  return (
    <Text>
      <Row>
        <Col xs={24} sm={{ offset: 3, span: 18 }} md={{ offset: 6, span: 12 }}>
          <h2>Inloggen</h2>
          {state === 'ERROR' && (
            <Alert
              message="Ongeldige inloggegevens!"
              type="error"
              showIcon
              closable
              style={{ marginBottom: '1.5rem' }}
            />
          )}
          <Spin spinning={state === 'PENDING'} size="large">
            <Form
              size="large"
              name="login"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Dit veld is verplicht.' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="Gebruikersnaam" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Dit veld is verplicht.' }]}
              >
                <Input
                  prefix={<LockOutlined />}
                  placeholder="Wachtwoord"
                  type="password"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Inloggen
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
      </Row>
    </Text>
  );
};

interface Data {
  username: string;
  password: string;
}

type State = 'READY' | 'PENDING' | 'DONE' | 'ERROR';
