import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { authState } from '../store';

export const RequireAuth: FC = ({ children }) => {
  const auth = useSnapshot(authState);

  if (!auth.jwt) return <Redirect to="/login" />;

  return <>{children}</>;
};
