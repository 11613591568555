import axios from 'axios';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { setSettings, setSettingsState, settingsState } from '../store';

export function useSettings() {
  const settings = useSnapshot(settingsState);

  useEffect(() => {
    if (settings.state === 'READY') {
      setSettingsState('LOADING');
      axios
        .get('/api/settings')
        .then((response) => {
          setSettings(response.data);
        })
        .catch(() => {
          setSettingsState('READY');
        });
    }
  }, [settings.state]);

  return settings;
}
