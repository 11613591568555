import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSettings } from '../hooks/useSettings';
import { useSlots } from '../hooks/useSlots';
import { sortByNumericKey } from '../lib';
import { CatalogEntry, checkoutState } from '../store';
import classes from './orderForm.module.css';

const MONTHS = [
  '',
  'Januari',
  'Februari',
  'Maart',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Augustus',
  'September',
  'Oktober',
  'November',
  'December'
];

const DAYS = [
  'Zondag',
  'Maandag',
  'Dinsdag',
  'Woensdag',
  'Donderdag',
  'Vrijdag',
  'Zaterdag'
];

const DATE = new Date();
DATE.setDate(DATE.getDate() + 7);

const D = DATE.getDate();
const M = DATE.getMonth() + 1;
const Y = DATE.getFullYear();

const NEXT_M = M === 12 ? 1 : M + 1;
const NEXT_Y = NEXT_M === 1 ? Y + 1 : Y;

const DAYS_CURRENT_MONTH = new Date(Y, M, 0).getDate();
const DAYS_NEXT_MONTH = new Date(NEXT_Y, NEXT_M, 0).getDate();

const SLOTS: Slot[] = [];

for (let i = D; i <= DAYS_CURRENT_MONTH; i++) {
  SLOTS.push({
    d: i,
    m: M,
    y: Y,
    text: `${DAYS[new Date(Y, M - 1, i).getDay()]} ${i} ${MONTHS[M]}`
  });
}
for (let i = 1; i <= DAYS_NEXT_MONTH; i++) {
  SLOTS.push({
    d: i,
    m: NEXT_M,
    y: NEXT_Y,
    text: `${DAYS[new Date(NEXT_Y, NEXT_M - 1, i).getDay()]} ${i} ${
      MONTHS[NEXT_M]
    }`
  });
}

export const OrderForm: FC<{ product: CatalogEntry }> = ({ product }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { settings, state } = useSettings();
  const { slots } = useSlots();
  const products = useMemo(
    () => [...product.products].sort(sortByNumericKey('capacity')),
    [product.products]
  );

  const [currentProduct, setCurrentProduct] = useState(products[0]);
  const [hasWarranty, setHasWarranty] = useState(false);
  const [hasSpoed, setHasSpoed] = useState(false);
  const total = useMemo(
    () =>
      Number(currentProduct.price) +
      (hasWarranty ? Number(settings.garantie.value) : 0) +
      (hasSpoed ? Number(settings.spoed.value) : 0),

    [
      settings?.garantie?.value,
      settings?.spoed?.value,
      hasSpoed,
      hasWarranty,
      currentProduct
    ]
  );

  const initialValues: Partial<Data> = {
    productId: products[0].id,
    warranty: false
  };

  if (state !== 'LOADED') return null;

  function onChangeProduct(id: number) {
    setCurrentProduct(products.find((product) => product.id === id)!);
    if (!hasSpoed) form.resetFields(['dateIndex']);
  }

  function onChangeWarranty(value: boolean) {
    setHasWarranty(value);
  }

  function onChangeSpoed(ev: CheckboxChangeEvent) {
    setHasSpoed(ev.target.checked);
  }

  function onFinish(data: Data) {
    checkoutState.products = [
      {
        name: `${product.name} (${product.voltage}V) Revisie ${currentProduct.capacity}Ah`,
        price: Number(currentProduct.price)
      },
      {
        name: `Garantie ${data.warranty ? 2 : 1} jaar`,
        price: data.warranty ? Number(settings.garantie.value) : 0
      }
    ];

    if (hasSpoed)
      checkoutState.products.push({
        name: 'Spoed (binnen 48 uur gereed)',
        price: Number(settings.spoed.value)
      });

    if (data.code)
      checkoutState.products.push({
        name: `Actie code: ${data.code}`,
        price: 0
      });

    checkoutState.date = {
      d: SLOTS[data.dateIndex].d,
      m: SLOTS[data.dateIndex].m,
      y: SLOTS[data.dateIndex].y
    };
    checkoutState.workload = currentProduct.slots;

    history.push('/checkout');
  }

  return (
    <>
      <h2>Reviseren</h2>
      <Form
        form={form}
        layout="horizontal"
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        size="large"
        colon={false}
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item
          name="productId"
          label="Capaciteit"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Select onChange={onChangeProduct}>
            {products.map((product) => (
              <Select.Option value={product.id} key={product.id}>
                {product.capacity}Ah voor € {product.price}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="warranty"
          label="Garantie"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
        >
          <Select onChange={onChangeWarranty}>
            <Select.Option value={false}>1 jaar (gratis)</Select.Option>
            <Select.Option value={true}>
              2 jaar (€ {settings.garantie.value})
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="dateIndex"
          label="Revisie datum"
          rules={[{ required: true, message: 'Dit veld in verplicht.' }]}
          extra="Revisie is binnen 8 dagen na revisie datum gereed."
        >
          <Select>
            {SLOTS.map((slot, index) => (
              <Select.Option
                className={
                  slot.text.startsWith('Zaterdag') ||
                  slot.text.startsWith('Zondag')
                    ? classes.weekend
                    : ''
                }
                disabled={
                  Number(currentProduct.slots) >
                    slots[`${slot.d}-${slot.m}-${slot.y}`] ??
                  Number(settings?.perDay?.value)
                }
                value={index}
                key={index}
              >
                {slot.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          extra="Dit veld is niet verplicht."
          name="code"
          label="Actie code"
        >
          <Input />
        </Form.Item>
        <Form.Item name="spoed" label="Spoed">
          <Checkbox onChange={onChangeSpoed}>
            Revisie is binnen 48 uur na revisie datum gereed (€{' '}
            {settings.spoed.value})
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={{ span: 24 }} sm={{ offset: 6, span: 18 }}>
              <Button size="large" type="primary" htmlType="submit">
                Bestel € {total}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

interface Data {
  productId: number;
  warranty: boolean;
  dateIndex: number;
  spoed: boolean;
  code: string;
}

interface Slot {
  d: number;
  m: number;
  y: number;
  text: string;
}
