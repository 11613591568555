import { FC } from 'react';
import { Link } from 'react-router-dom';
import classes from './footer.module.css';

export const Footer: FC = () => (
  <footer className={classes.footer}>
    &copy; 2022 Helder Accu Service |{' '}
    <Link to="/voorwaarden">Algemene voorwaarden</Link> |{' '}
    <Link to="/contact">Contact</Link>
  </footer>
);
